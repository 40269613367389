body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* The emerging W3C standard
   that is currently Firefox-only */
* {
	scrollbar-width: thin;
	scrollbar-color: #f1f1f1 #888;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
	width: 12px;
}
*::-webkit-scrollbar-track {
	background: #f1f1f1;
}
*::-webkit-scrollbar-thumb {
	background-color: #888;
	border-radius: 20px;
	border: 3px solid #f1f1f1;
}
